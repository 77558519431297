exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-breeder-profile-index-js": () => import("./../../../src/pages/account/breeder-profile/index.js" /* webpackChunkName: "component---src-pages-account-breeder-profile-index-js" */),
  "component---src-pages-account-breeder-profile-list-payment-js": () => import("./../../../src/pages/account/breeder-profile/list-payment.js" /* webpackChunkName: "component---src-pages-account-breeder-profile-list-payment-js" */),
  "component---src-pages-account-breeder-profile-list-puppy-js": () => import("./../../../src/pages/account/breeder-profile/list-puppy.js" /* webpackChunkName: "component---src-pages-account-breeder-profile-list-puppy-js" */),
  "component---src-pages-account-breeder-profile-upload-puppy-js": () => import("./../../../src/pages/account/breeder-profile/upload-puppy.js" /* webpackChunkName: "component---src-pages-account-breeder-profile-upload-puppy-js" */),
  "component---src-pages-account-buyer-profile-index-js": () => import("./../../../src/pages/account/buyer-profile/index.js" /* webpackChunkName: "component---src-pages-account-buyer-profile-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-dogs-js": () => import("./../../../src/pages/dogs.js" /* webpackChunkName: "component---src-pages-dogs-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-breeder-detail-js": () => import("./../../../src/templates/breeder-detail.js" /* webpackChunkName: "component---src-templates-breeder-detail-js" */),
  "component---src-templates-puppy-detail-js": () => import("./../../../src/templates/puppy-detail.js" /* webpackChunkName: "component---src-templates-puppy-detail-js" */)
}

