module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Plus Jakarta Sans","file":"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap","weights":["400","500","600","700"]},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap","weights":["400","500","700"]},{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap","weights":["400","500","600","800"]},{"name":"Sniglet","file":"https://fonts.googleapis.com/css2?family=Sniglet&display=swap","weights":["400"]},{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap","weights":["400","600","700"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e6dc9cba9b624d551ce139184bafb17"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
